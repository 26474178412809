import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  getusers,
  deleteuser,
  deployuser,
  verifyUserEmailManual,
  postRequest,
} from "backendServices/ApiCalls";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AddTask, Login } from "@mui/icons-material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Div from "@jumbo/shared/Div/Div";
import { Link } from "react-router-dom";

function ManageCities() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState({});
  const [citydata, setCityData] = useState([]);

  console.log("citydata", citydata);

  const CitiesData = () => {
    postRequest(
      "/getallcities",
      "",
      (response) => {
        if (response?.data?.status === "success") {
          setCityData(response?.data?.data);
          setIsMainLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setIsMainLoading(false);
      }
    );
  };

  useEffect(() => {
    CitiesData();
  }, []);
  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const columns = [
    {
      field: "name",
      headerName: "City Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "country_code", // New column for Full Name
      headerName: "Country Code",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        const username = params?.row?.username;
        return (
          <>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleActionClick(params.row.id, "delete")}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const handleDelete = () => {
    if (selectedUserId) {
      let params = {
        id: selectedUserId,
      };
      console.log("params", params);
      postRequest(
        "/deletecity",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            CitiesData();
          }
        },
        (error) => {
          console.log(error?.response?.data);
        }
      );
    }
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setSelectedUserId(null);
    setOpenDialog(false);
  };

  const handleCloseApproveDialog = () => {
    setSelectedUserId(null);
    setOpenApproveDialog(false);
  };

  const handleCloseEmailDialog = () => {
    setSelectedUserId(null);
    setOpenEmailDialog(false);
  };

  const handleActionClick = (id, action) => {
    setSelectedUserId(id);
    if (action === "delete") {
      setOpenDialog(true);
    }
  };

  // Initialize the counter

  // Assign a unique sequential ID to each row

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (isMainLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <JumboDemoCard
      title={"Inactive Users"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} sx={{textAlign: "right" }}>
          <Link to='/add-city'>
            <Button variant="contained">Add City</Button>
          </Link>
        </Grid>
				<Grid item xs={12} sm={12}>
				<Box sx={{ height: 800, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          rows={citydata}
          getRowId={(row) => generateRowId()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[15, 30, 75, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
				</Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this city?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
    </JumboDemoCard>
  );
}

export default ManageCities;
