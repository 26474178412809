import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Tooltip } from "recharts";
import { Chip, IconButton } from "@mui/material";

const VISIBLE_FIELDS = ['sr', 'amount',  'status','details','rejectreason', 'createdat'];

function PayoutReport() {
	const [copiedRows, setCopiedRows] = useState([]);
	const [unilevelbonus, setUnilevelBonusData] = useState([])
  const UnilevelData = () => {
    let params = {
      type: "payout"
    }
    postRequest('/report', params, (response) => {
      setUnilevelBonusData(response?.data?.data)
    }, (error) => {
      console.log(error?.response?.data);
    })
  }
  console.log("unilevelbonus", unilevelbonus)

  useEffect(() => {
    console.log('useeffect')
    UnilevelData();
  }, [])

	const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        }
      }
    }
  }

	const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#000',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }

	// const columns = [
  //   {
  //     field: "amount",
  //     headerName: "Amount",
  //     dataGeneratorUniquenessEnabled: true,
  //     width: 150,
  //     editable: true,
  //     groupable: false,
  //     aggregable: false,
  //     renderCell: (params) => `$${params.value}`
  //   },
  //   {
  //     field: "senderusername",
  //     headerName: "Sender",
  //     dataGeneratorUniquenessEnabled: true,
  //     width: 150,
  //     editable: true,
  //     groupable: false,
  //     aggregable: false,
  //   },
  //   {
  //     field: "receiverusername",
  //     headerName: "Receiver",
  //     dataGeneratorUniquenessEnabled: true,
  //     width: 150,
  //     editable: true,
  //     groupable: false,
  //     aggregable: false,
  //   },
  //   {
  //     field: "createdat",
  //     headerName: "Date",
  //     dataGeneratorUniquenessEnabled: true,
  //     width: 150,
  //     editable: true,
  //     groupable: false,
  //     aggregable: false,
  //   },
  //   {
  //     field: "details",
  //     headerName: "Details",
  //     dataGeneratorUniquenessEnabled: true,
  //     width: 400,
  //     editable: true,
  //     groupable: false,
  //     aggregable: false,
  //   },

  // ]

	const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const rows = unilevelbonus
  let idCounter = 1;
	const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  return (
    <JumboDemoCard title={'Payout Summary'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 24 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'amount') {
                return {
                  field,
                  headerName: 'Amount',
                  width: 150,
                  renderCell: (params) => `$${params.value}`
                };
              }  else if (field === 'rejectreason') {
                return {
                  field,
                  headerName: 'Reason',
                  width: 150,
                  renderCell: (params) => {
                    const isCopied = copiedRows.includes(params.row.id);
                    
                    const handleCopyClick = () => {
                      navigator.clipboard.writeText(params.value)
                        .then(() => {
                          setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                        })
                        .catch((error) => {
                          console.error('Copy failed:', error);
                        });
                    };
          
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isCopied ? (
                          <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                        ) : (
                          params.value === "" || params.value === null ? '' : (
                          <Tooltip title="Copy Details" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: '4px' }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>)
                        )}
                        <span>{params.value}</span>
                      </div>
                    );
                  },
                };
              } else if (field === 'status') {
                return {
                  field,
                  headerName: 'Status',
                  width: 150,
                  renderCell: (params) => (
                    <Chip
                      label={params.value}
                      color={params.value === 'approved' ? 'success' 
                      : params.value === 'rejected' ? 'error'
                      : params.value === 'pending' ? 'warning'
                      :
                      'default'  
                      }
                      size="small"
                    />
                  ),
                };
              }else if (field === 'details') {
                return {
                  field,
                  headerName: 'Details',
                  width: 350,
                  renderCell: (params) => {
                    const isCopied = copiedRows.includes(params.row.id);
                    
                    const handleCopyClick = () => {
                      navigator.clipboard.writeText(params.value)
                        .then(() => {
                          setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                        })
                        .catch((error) => {
                          console.error('Copy failed:', error);
                        });
                    };
          
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isCopied ? (
                          <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                        ) : (
                          <Tooltip title="Copy Details" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: '4px' }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <span>{params.value}</span>
                      </div>
                    );
                  },
                };
              } else if (field === 'createdat') {
                return {
                  field,
                  headerName: 'Date (YYYY-MM-DD)',
                  width: 200,
                  renderCell:(params) => {
                    const dateObject = new Date(params.row.createdat);
                  // Extract the year, month, and day components
                  const year = dateObject.getFullYear();
                  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
                  const day = dateObject.getDate().toString().padStart(2, '0');
                  // Create a formatted date string (e.g., "YYYY-MM-DD")
                  const formattedDate = `${year}-${month}-${day}`;
                  return formattedDate
                  }
                };
              }

              return {
                field,
                headerName: field,
                width: 150,
              };;
            }),
            
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[24, 48, 96]}
          columnResize={true} // Enable column resizing
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default PayoutReport;
