import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { Button, IconButton, Tooltip } from "@mui/material";
import {
  FileCopy as FileCopyIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { Link } from "react-router-dom";
import { postRequest } from "backendServices/ApiCalls";

const VISIBLE_FIELDS = [
  "sr",
  "coinname",
  "walletqrcode",
  "walletmessage",
  "walletaddress",
];

const ManageDepositWallet = () => {
  const [isloading, setIsLoading] = useState(true);
  const [copiedRows, setCopiedRows] = useState([]);
  const [payoutdata, setPayoutData] = useState([]);
  const useSweetAlert = (PayoutData) => {
    const [alertData, setAlertData] = useState({
      show: false,
      message: "",
      variant: "",
      confirmFunction: null,
      action: "",
    });
    const handleReject = (id) => {
      return new Promise((resolve) => {
        let params = {
          tid: id,
        };
  
        postRequest(
          "deletewallet",
          params,
          (response) => {
            if (response?.data?.status === "success") {
              Swal.fire({
                title: "Wallet deleted",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "OK",
              }).then(() => {
                setAlertData((prevAlertData) => ({
                  ...prevAlertData,
                  show: false,
                }));
              });
              PayoutData();
              resolve();
            }
          },
          (error) => {
            console.log(error?.response?.data);
            resolve();
          }
        );
      });
    };
  
    const showSweetAlert = (
      title,
      description,
      confirmFunction,
      variant,
      action
    ) => {
      setAlertData({
        show: true,
        message: description,
        variant: variant,
        confirmFunction: confirmFunction,
        action: action,
      });
    };
  
    useEffect(() => {
      if (alertData.show) {
        const hasTextField = alertData.action === "reject";
        Swal.fire({
          title: alertData.title,
          text: alertData.message,
          icon: alertData.variant,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return new Promise(async (resolve) => {
              await alertData.confirmFunction();
              resolve();
            });
          },
          allowOutsideClick: () => !Swal.isLoading(),
          didOpen: () => {
            if (hasTextField) {
              const input = document.getElementById("reason-input");
              if (input) {
                input.focus();
              }
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle the case when "Yes" is clicked
          } else {
            // Handle the case when "No" is clicked
          }
        });
      }
    }, [alertData]);
  
    return {
      showSweetAlert,
      handleReject,
    };
  };


  const PayoutData = () => {
    setIsLoading(true);
    let params = {
      keynames: "'depositwallet'",
    };
    postRequest(
      "getdepositwallets",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setIsLoading(false);
          setPayoutData(response?.data);
        }
      },
      (error) => {
        setIsLoading(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    PayoutData();
  }, []);

  const { showSweetAlert, handleReject } = useSweetAlert(PayoutData);

  const handleActionClick = (id, action) => {
    let title = "";
    let description = "";
    let confirmFunction = null;
    let variant = "";

    if (action === "reject") {
      title = "Delete Wallet";
      description = "Are you sure you want to delete this wallet?";
      confirmFunction = () => handleReject(id);
      variant = "error";
    }
    showSweetAlert(title, description, confirmFunction, variant, action);
  };

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (isloading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const rows = payoutdata?.data?.entries || [];
  let idCounter = 1;
  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));
  return (
    <JumboDemoCard
      title={"Manage Deposit Wallet"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 400, width: 1 }}>
        <Div
          style={{ display: "flex", justifyContent: "right", marginTop: -40 }}
        >
          <Link to={"/add-deposit-wallet"}>
            <Button variant="contained">Add Deposit Wallet</Button>
          </Link>
        </Div>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  coinname: false,
                  walletmessage: false,
                  walletaddress: false,
                  walletqrcode: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === "sr") {
                return {
                  field,
                  headerName: "Sr",
                  width: 60,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === "coinname") {
                return {
                  field,
                  headerName: "Coin",
                  width: 100,
                };
              } else if (field === "walletmessage") {
                return {
                  field,
                  headerName: "Message",
                  width: 250,
                  renderCell: (params) => `${params.value}`,
                };
              } else if (field === "walletqrcode") {
                return {
                  field,
                  headerName: "QR Code",
                  width: 150,
                  renderCell: (params) => (
                    <img
                      src={payoutdata?.picturelink + params.value}
                      alt="Coin"
                      width={50}
                      height={50}
                    />
                  ),
                };
              } else if (field === "walletaddress") {
                return {
                  field,
                  headerName: "Wallet Address",
                  width: 250,
                  renderCell: (params) => {
                    const isCopied = copiedRows.includes(params.row.id);

                    const handleCopyClick = () => {
                      navigator.clipboard
                        .writeText(params.value)
                        .then(() => {
                          setCopiedRows((prevCopiedRows) => [
                            ...prevCopiedRows,
                            params.row.id,
                          ]);
                        })
                        .catch((error) => {
                          console.error("Copy failed:", error);
                        });
                    };

                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {isCopied ? (
                          <CheckCircleIcon
                            style={{ marginRight: "4px", color: "green" }}
                          />
                        ) : (
                          <Tooltip title="Copy Hash" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: "4px" }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <span>{params.value}</span>
                      </div>
                    );
                  },
                };
              } else {
                return {
                  field,
                  headerName: field,
                  width: 150,
                };
              }
            }),
            {
              field: "actions",
              headerName: "Actions",
              width: 120,
              renderCell: (params) => (
                <>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      onClick={() =>
                        handleActionClick(params.row.tid, "reject")
                      }
                      sx={{ color: "error.main" }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default ManageDepositWallet;
