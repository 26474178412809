import React, { useEffect, useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { CircularProgress, Grid, List } from "@mui/material";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { postRequest, getsettingsdata } from "backendServices/ApiCalls";
import * as yup from "yup";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";

const AddDepositWallet = () => {
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [payoutdata, setPayoutData] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const initialValues = {
    walletaddress: payoutdata?.walletaddress,
    walletmessage: payoutdata?.walletmessage,
    coinname: payoutdata?.coinname
  };

  const validationSchema = yup.object({
    walletaddress: yup.string().required("Wallet address is required"),
    coinname: yup.string().required("Crypto coin is required"),
    walletmessage: yup.string().required("Information  is required"),
  });


  const fetchzoomdata = () => {
    setIsLoading(true)
    const params = {
      keynames: "'depositwallet'"
    };
    getsettingsdata(params, (response) => {
      console.log("response?.data?.data?.values", response?.data?.data?.values[0].keyvalue)
      const jsonObject = JSON.parse(response?.data?.data?.values[0].keyvalue);
      setPayoutData(jsonObject);
      setIsLoading(false);
    });
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    // if (!image) {
    //   setalertData({
    //     show: true,
    //     message: 'Invalid file type. Please select an image file.',
    //     variant: 'error'
    //   });
    //   setSubmitting(false);
    //   return;
    // }
    let params = {
      walletaddress: data.walletaddress,
      walletmessage: data.walletmessage,
      coinname: data.coinname,
    }
    postRequest(
      "postdepositwallet",
      params,
      (response) => {
        setSubmitting(false);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again later.",
            variant: "error",
          });
        }
      },
      (error) => {
        setSubmitting(false);
        setalertData({
          show: true,
          message: "Something went wrong. Please try again.",
          variant: "error",
        });
      }
    );

  };

  useEffect(() => {
    fetchzoomdata()
  }, [])

  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  };

  if (isLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }
  return (
    <JumboCardQuick
      title={"Update Deposit Wallet"}
      noWrapper
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding sx={{ mb: 2 }}>
        <Formik
          enableReinitialize='true'
          validateOnChange='true'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleSubmit(data, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2} sx={{ p: 2 }}>

                <Grid item sm={12} sx={{ mt: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name={"coinname"}
                    label={"Coin Name"}
                    type="text"
                  />
                </Grid>

                <Grid item sm={12} sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name={"walletaddress"}
                    label={"Wallet Address"}
                    type="text"
                  />
                </Grid>

                <Grid item sm={12} sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name={"walletmessage"}
                    label={
                      "Any specific information relative to deposit account"
                    }
                    type="text"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>

              <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Update
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default AddDepositWallet;
