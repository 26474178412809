import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { Chip, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { format } from "date-fns";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CancelIcon from "@mui/icons-material/Cancel";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";

const initialState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
      },
    },
  },
};

const NewPackageRequests = () => {
  const [payoutdata, setPayoutData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [alertopen, setAlertOpen] = React.useState(false);
  const [test, setTest] = React.useState(false);
  const [imagUrl, setImageUrl] = useState();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const Swal = useSwalWrapper();

  const approveOrder = (orderId) => {
    Swal.fire({
      title: "Are you sure to update Status?",
      text: "You are going apporve status ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Approve!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleOrderStatus(orderId);
        Swal.fire(
          "Approved!",
          "Package has been approved and delivered to user.",
          "success"
        );
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "package is not approved", "error");
      }
    });
  };

  const handleOrderStatus = (id) => {
    let params = {
      action: "approved",
      tid: id
    };
    postRequest(
      "/depositaction",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTest(true)
        }
        else {
          setalertData({
            show: true,
            message: "Some thing went wrong. Please try again later.",
            variant: "error",
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handleOrderReject = (id) => {
    let params = {
      orderstatus: "rejected",
      tid: id,
    };
    postRequest(
      "/rejectpackage",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTest(true)
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const PayoutData = () => {
    setLoader(true)
    postRequest(
      "/getpackages",
      { status: "pending" },
      (response) => {
        console.log("response", response);
        if (response?.data?.status === "success") {
          setPayoutData(response?.data?.data);
          setImageUrl(response?.data?.picUrl);
          setLoader(false)
        }
      },
      (error) => {
        setLoader(false)
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    PayoutData();
  }, [test]);

  const columns = [
    {
      field: "username",
      headerName: "User",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
      groupable: false,
      aggregable: false,
    },
   
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return `$${params.value}`;
      },
    },
    {
      field: "paymentmethod",
      headerName: "Payment Method",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "walletaddress",
      headerName: "Wallet Address",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "picture",
      headerName: "Image",
      width: 200,
      renderCell: (params) => (
        <img
          src={`${imagUrl}${params.row.paymentproof}`} // Assuming your image URLs are constructed like this
          alt={`Product ${params.row.title}`}
          style={{ width: "50%", height: "auto" }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value === "pending" ? (
          <Chip color="warning" label="Pending" />
        ) : params.value === "rejected" ? (
          <Chip color="error" size="small" label="Rejected" />
        ) : (
          <Chip color="success" size="small" label="Approved" />
        );
      },
    },
    {
      field: "createdat",
      headerName: "Requested On",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => {
        return format(new Date(params.value), "dd-MM-yyyy");
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <>
          <Tooltip title="Approve" placement="top">
            <IconButton
              onClick={() =>
                handleOrderStatus(
                  params.row.id,
                )
              }
              sx={{ color: "success.main" }}
            >
              <AddTaskIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject" placement="top">
            <IconButton
              onClick={() => handleOrderReject(params.row.id)}
              sx={{ color: "error.main" }}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const rows = payoutdata;
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <JumboDemoCard
      title={"Pending Packages Requests"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )} 
      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default NewPackageRequests;
