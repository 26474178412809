import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {DeleteMiniAdminApi, GetMiniAdminApi, getadmins, removeadmin } from 'backendServices/ApiCalls';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton} from "@mui/material";
import Alert from "@mui/material/Alert";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import {  useTranslation } from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";
import Div from '@jumbo/shared/Div/Div';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});


const VISIBLE_FIELDS = ['sr', 'amount', 'status','date'];
const Inactive = () => {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
  const [alertData, setalertData] = useState({
    show:false,
    message:"",
    variant:""
})
  
  const [miniadmin,setMiniAdmin]=useState([]) 

  const GetMiniAdmin =()=>{
    GetMiniAdminApi((response) => {
      if(response?.data?.status === "success") {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
      }));
        setMiniAdmin(processedData)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }

useEffect(()=>{
  GetMiniAdmin();
},[])
const generateRowId = () => {
  // Generate a random string or use any other logic to create a unique ID
  return `row-${Math.random().toString(36).substr(2, 9)}`;
};

const rowsWithIndex = miniadmin?.map((row) => ({ ...row, id: row.id }));

const columns = [
  {
    field: "index",
    headerName: "#",
    width: 80,

  },
  // {
  //   field: "id",
  //   headerName: "Sr#",
  //   width: 100,
  //   editable: true,
  //   groupable: false,
  //   aggregable: false,
  //   valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist

  // },

{
  field: "username",
  headerName: "User Name",  
  dataGeneratorUniquenessEnabled: true,
  width: 200,
  editable: true,
  groupable: false,
  aggregable: false,
  aggregable: false,

},
{
  field: "firstname",
  headerName: "First Name",  
  dataGeneratorUniquenessEnabled: true,
  width: 200,
  editable: true,
  groupable: false,
  aggregable: false,
  aggregable: false,

},
{
  field: "lastname",
  headerName: "Last Name",  
  dataGeneratorUniquenessEnabled: true,
  width: 200,
  editable: true,
  groupable: false,
  aggregable: false,
  aggregable: false,
},

{
  field: "email",
  headerName: "Email",  
  dataGeneratorUniquenessEnabled: true,
  width: 250,
  editable: true,
  groupable: false,
  aggregable: false,
  aggregable: false,
},




{
  field: "delete",
  headerName: "Delete",
  width: 120,
  editable: true,
  groupable: false,
  aggregable: false,
  renderCell: (params) => (
    <DeleteIcon
         onClick={() => {
            setOpen(true)
            setSelectedRowId(params.row.id);
        }}
        style={{ cursor: 'pointer', color: "red" }}
    />
),
},





]

const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
    }
}
}
}



const handleDelete=()=>{
  let params = {
    id:selectedRowId,
  }
  DeleteMiniAdminApi(params,(response) => {
      if(response?.data?.status === "success") {
        setalertData({
          show:true,
          message:'User Deleted',
          variant:"success"
      })
      GetMiniAdmin();
      setOpen(false)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })

}


// const rows= usersdata

let idCounter = 1; // Initialize the counter

// Assign a unique sequential ID to each row
// const rowsWithId = rows.map((row) => ({
//   ...row,
//   id: idCounter++, // Assign the current counter value and then increment it
// }));
// const gridDesign = {
//   '& .MuiDataGrid-toolbarContainer': {
//     '& .MuiButton-text': {
//       fontSize: '13px !important',
//       color: '#000',
//     },
//     '& .MuiBadge-badge': {
//       backgroundColor: '#074682',
//     },
//     '& .MuiInput-root':{
//       borderRadius: 2,
//       paddingLeft: 2,
//       overflow: 'hidden',
//     },

//   } 
// }
const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}

  return (
    <JumboDemoCard
    title={t('pages.title.adminslist')}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>
{
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }
        <Div>

<Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setOpen(false)}
    aria-describedby="alert-dialog-slide-description"
>
        <DialogTitle sx={{fontSize: 30}}>{t('pages.title.alert')}</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-slide-description" sx={{fontSize: 20}}>
{t('pages.title.deleteIcon')}
</DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleDelete}>{t('pages.title.yes')}</Button>
        <Button onClick={() => setOpen(false)}>{t('pages.title.no')}</Button>
    </DialogActions>
</Dialog>
</Div>

    <Box sx={{ height: 400, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rowsWithIndex}
       
        getRowId={(row) => generateRowId()} 
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default Inactive