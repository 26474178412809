import React from "react";
import CardHeader from "@mui/material/CardHeader";
import {
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PortfolioBalance = ({ props, dashboardApiData }) => {
  const { t } = useTranslation();
  const date = new Date(); //Get the day of the week of particular date mentioned by the user
  var day = date.getDay();
  var day1 = date.getDate();
  day = (day / 7) * 100;
  day1 = (day1 / 30) * 100;
  const dayp = day + "%";
  const day1p = day1 + "%";
  return (
    <Card>
      <CardHeader title={"Payout Details"} />
      <CardContent sx={{ pt: 2 }}>
        <Stack direction={"row"} spacing={3}>
          <Grid item xs={6}>
            <Typography variant={"h2"}>{props?.totalroi}</Typography>
            <Typography variant={"body1"} sx={{ mb: 3 }}>
              {"Overall Payout"}
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <Button
                variant={"contained"}
                color={"secondary"}
                component={Link}
                to={"/updatewithdrawal"}
              >
                {"Manage"}
              </Button>
              <Button
                variant={"contained"}
                color={"inherit"}
                disableElevation
                component={Link}
                to={"/pending-payout"}
              >
                {"Summary"}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"h5"} color={"text.secondary"} mb={2}>
              {"Time Period"}
            </Typography>
            <Typography variant={"h6"} color={"text.primary"}>
              {"Current Month"}
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                $
                {dashboardApiData?.currentMonth_payout
                  ? dashboardApiData?.currentMonth_payout.toFixed(2)
                  : "0"}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"success"}
              value={day}
              sx={{
                width: { dayp },
                borderRadius: 4,
                height: 5,
                mb: 2,
                backgroundColor: "#E9EEEF",
              }}
            />
            <Typography variant={"h6"} color={"text.primary"}>
              {"All Time"}
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                $
                {dashboardApiData?.allTime_payout
                  ? dashboardApiData?.allTime_payout.toFixed(2)
                  : "0"}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"warning"}
              value={day1}
              sx={{
                width: { day1p },
                borderRadius: 4,
                height: 5,
                backgroundColor: "#E9EEEF",
              }}
            />
          </Grid>
        </Stack>
      </CardContent>
      <CardActions sx={{ pt: 0.5, pb: 2 }}>
        {/* <Button startIcon={<AddIcon />} size="small">{t('pages.title.viewr')}</Button> */}
      </CardActions>
    </Card>
  );
};

export default PortfolioBalance;
